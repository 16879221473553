var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{staticClass:"font font-weight-medium",attrs:{"inset":"","persistent":"","fullscreen":"","scrollable":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',{attrs:{"tile":""}},[(_vm.pageLoading)?_c('SkeletonPreloader',{attrs:{"type":"table"}}):_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{staticClass:"my-0",attrs:{"color":"primary","indeterminate":"","height":"3"}}):_vm._e(),(_vm.details && _vm.details.orderLineItemId)?_c('div',{staticClass:"d-flex flex-row "},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold black--text font-size-md ft mx-1 px-3 pt-3"},[_vm._v("Generate Deliveries")]),_c('span',{staticClass:"font-weight-bold black--text text-sm ft mx-1 px-3 pb-1 pt-1"},[_vm._v("Order Reference : "+_vm._s(_vm.details.orderLineItemId.orderReference)+" ")]),_c('span',{staticClass:"font-weight-bold black--text text-sm ft mx-1 px-3 pb-2"},[_vm._v("Date : "+_vm._s(_vm._f("timestamp")(_vm.details.date,"Do MMMM, YYYY"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-8",attrs:{"small":"","right":"","top":"","absolute":"","fab":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])]}}],null,false,362269145)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Close")])])],1):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createDeliveries)}}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"width":"100%","overflow-y":"scroll","height":"100%"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 px-2 pb-4"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 "},[(_vm.lineItem)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 "},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 px-2"},[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:" ft font-weight-medium font-size-sm ma-1",staticStyle:{"flex-basis":"22%"},attrs:{"dense":"","outlined":"","items":_vm.branches,"error-messages":errors[0],"item-value":"id","item-text":"name","readonly":!_vm.lineItem.isNewLineItem,"label":"Branch"},model:{value:(_vm.lineItem.branchId),callback:function ($$v) {_vm.$set(_vm.lineItem, "branchId", $$v)},expression:"lineItem.branchId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:" ft font-weight-medium font-size-sm ma-1",attrs:{"dense":"","outlined":"","items":_vm.products,"item-value":"id","item-text":"name","error-messages":errors[0],"readonly":"","label":"Product"},model:{value:(_vm.lineItem.productId),callback:function ($$v) {_vm.$set(_vm.lineItem, "productId", $$v)},expression:"lineItem.productId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"rules":"required|numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-sm ma-1",attrs:{"dense":"","outlined":"","error-messages":errors[0],"label":"Quantity (Litres)","readonly":!_vm.lineItem.isNewLineItem},model:{value:(_vm.lineItem.quantity),callback:function ($$v) {_vm.$set(_vm.lineItem, "quantity", $$v)},expression:"lineItem.quantity"}})]}}],null,true)})],1),(_vm.sharedProductsAndQuantity.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1  generate-order"},[_c('h4',{staticClass:"pb-2 ft font-weight-bold"},[_vm._v(" Quantity for each branch ")]),_vm._l((_vm.sharedProductsAndQuantity),function(subLineItem,index){return _c('div',{key:index,staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:" ft font-weight-medium font-size-sm ma-1",staticStyle:{"flex-basis":"22%"},attrs:{"dense":"","outlined":"","items":subLineItem.branchList,"error-messages":errors[0],"item-value":"id","item-text":"name","readonly":!subLineItem.isNewLineItem,"label":"Branch"},model:{value:(subLineItem.branchId),callback:function ($$v) {_vm.$set(subLineItem, "branchId", $$v)},expression:"subLineItem.branchId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:" ft font-weight-medium font-size-sm ma-1",attrs:{"dense":"","outlined":"","items":_vm.products,"item-value":"id","item-text":"name","error-messages":errors[0],"readonly":"","label":"Product"},model:{value:(subLineItem.productId),callback:function ($$v) {_vm.$set(subLineItem, "productId", $$v)},expression:"subLineItem.productId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"rules":"required|numeric","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-sm ma-1",attrs:{"dense":"","outlined":"","error-messages":errors[0],"label":"Quantity (Litres)","readonly":!subLineItem.isNewLineItem},model:{value:(subLineItem.quantity),callback:function ($$v) {_vm.$set(subLineItem, "quantity", $$v)},expression:"subLineItem.quantity"}})]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.sharedProductsAndQuantity.length > 0)?_c('v-btn',_vm._g({staticClass:"mt-1",attrs:{"fab":"","small":"","color":"red","icon":""},on:{"click":function($event){return _vm.removeLineItem(index)}}},on),[_c('i',{staticClass:"material-icons font-size-md"},[_vm._v("close")])]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Remove Item")])])],1)})],2):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row pb-2",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{attrs:{"small":"","color":"primary","text":"","disabled":parseInt(_vm.trackLineItemQuantity) -
                                _vm.sharedProductsAndQuantity.reduce(
                                  function (acc, item) {
                                    return (acc += isNaN(
                                      parseInt(item.quantity)
                                    )
                                      ? 0
                                      : parseInt(item.quantity));
                                  },
                                  0
                                ) <
                                0 || _vm.loading},on:{"click":function($event){return _vm.pushToLineItem(_vm.lineItem)}}},[_c('i',{staticClass:"material-icons font-size-md ml-n2"},[_vm._v("add")]),_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Share Quantity")])])],1)])])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-row mr-2 mt-2",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loading ||
                        _vm.sharedProductsAndQuantity.length === 0 ||
                        parseInt(_vm.trackLineItemQuantity) -
                          _vm.sharedProductsAndQuantity.reduce(function (acc, item) {
                            return (acc += isNaN(parseInt(item.quantity))
                              ? 0
                              : parseInt(item.quantity));
                          }, 0) <
                          0,"loading":_vm.loading}},[_c('span',{staticClass:"ft"},[_vm._v("Generate Delivery")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mx-2 mt-2"},[_c('v-expansion-panels',{staticClass:" box-shadow-light",attrs:{"flat":"","focusable":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('span',{staticClass:" ft font-weight-bold font-size-md "},[_vm._v("View already shared products and associated branches")]),_c('i',{staticClass:"material-icons-outlined mr-3 primary--text"},[_vm._v("touch_app")])])]),_c('v-expansion-panel-content',[(_vm.details)?_c('v-data-table',{staticClass:" ft",attrs:{"flat":"","headers":_vm.headers.concat( [{
                              text: 'Delivery Status',
                              value: 'isDeliveryAccepted'
                            }]
                          ),"items":_vm.details.orderLineItemId.productDeliveries,"fixed-header":""},scopedSlots:_vm._u([{key:"item.branchId",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:" ft font-weight-bold"},[_vm._v(_vm._s(item.branchId.name))])])]}},{key:"item.productId",fn:function(){return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:" ft font-weight-bold"},[_vm._v(_vm._s(_vm.details.productId.name))])])]},proxy:true},{key:"item.isDeliveryAccepted",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isDeliveryAccepted
                                  ? '#9efb9e66'
                                  : '#f98c6b',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                                  color: item.isDeliveryAccepted
                                    ? 'rgba(3, 91, 3, 0.83)'
                                    : '#862c00'
                                })},[_vm._v(_vm._s(item.isDeliveryAccepted ? "Confirmed" : "Pending"))])])]}}],null,true)}):_vm._e()],1)],1)],1)],1)])])])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }