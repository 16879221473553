<template>
  <v-bottom-sheet
    class="font font-weight-medium"
    inset
    v-model="state"
    persistent
    fullscreen
    scrollable
  >
    <v-card tile>
      <SkeletonPreloader v-if="pageLoading" type="table" />
      <v-card-text v-else>
        <v-progress-linear
          v-if="loading"
          color="primary"
          indeterminate
          class="my-0"
          height="3"
        ></v-progress-linear>
        <div v-if="details && details.orderLineItemId" class="d-flex flex-row ">
          <div class="d-flex flex-column">
            <span
              class="font-weight-bold black--text font-size-md ft mx-1 px-3 pt-3"
              >Generate Deliveries</span
            >
            <span
              class="font-weight-bold black--text text-sm ft mx-1 px-3 pb-1 pt-1"
              >Order Reference : {{ details.orderLineItemId.orderReference }}
            </span>
            <span class="font-weight-bold black--text text-sm ft mx-1 px-3 pb-2"
              >Date : {{ details.date | timestamp("Do MMMM, YYYY") }}
            </span>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click.stop="close"
                small
                right
                top
                absolute
                fab
                class="mt-8"
                color="primary"
              >
                <i class="material-icons">close</i>
              </v-btn>
            </template>
            <span class="ft font-weight-medium">Close</span>
          </v-tooltip>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(createDeliveries)">
            <div
              class="d-flex flex-column flex-grow-1"
              style="width: 100%;overflow-y: scroll;height: 100%;"
            >
              <div class="d-flex flex-column flex-grow-1">
                <div class="d-flex flex-column flex-grow-1 px-2 pb-4">
                  <div class="d-flex flex-column flex-grow-1 ">
                    <div
                      v-if="lineItem"
                      class="d-flex flex-column flex-grow-1 "
                    >
                      <div class="d-flex flex-row flex-grow-1 px-2">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-column flex-grow-1"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-autocomplete
                            dense
                            outlined
                            :items="branches"
                            :error-messages="errors[0]"
                            item-value="id"
                            item-text="name"
                            :readonly="!lineItem.isNewLineItem"
                            label="Branch"
                            v-model="lineItem.branchId"
                            class=" ft font-weight-medium font-size-sm ma-1"
                            style="flex-basis: 22%"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-column flex-grow-1"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-autocomplete
                            dense
                            outlined
                            :items="products"
                            item-value="id"
                            item-text="name"
                            :error-messages="errors[0]"
                            readonly
                            label="Product"
                            v-model="lineItem.productId"
                            class=" ft font-weight-medium font-size-sm ma-1"
                          />
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required|numeric"
                          tag="div"
                          class="d-flex flex-column flex-grow-1"
                        >
                          <v-text-field
                            dense
                            outlined
                            :error-messages="errors[0]"
                            v-model="lineItem.quantity"
                            class=" ft font-weight-medium font-size-sm ma-1"
                            label="Quantity (Litres)"
                            :readonly="!lineItem.isNewLineItem"
                          />
                        </ValidationProvider>
                      </div>
                      <div
                        v-if="sharedProductsAndQuantity.length > 0"
                        class="d-flex flex-column flex-grow-1  generate-order"
                      >
                        <h4 class="pb-2 ft font-weight-bold">
                          Quantity for each branch
                        </h4>
                        <div
                          v-for="(subLineItem,
                          index) in sharedProductsAndQuantity"
                          :key="index"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <ValidationProvider
                            tag="div"
                            class="d-flex flex-column flex-grow-1"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-autocomplete
                              dense
                              outlined
                              :items="subLineItem.branchList"
                              :error-messages="errors[0]"
                              item-value="id"
                              item-text="name"
                              :readonly="!subLineItem.isNewLineItem"
                              label="Branch"
                              v-model="subLineItem.branchId"
                              class=" ft font-weight-medium font-size-sm ma-1"
                              style="flex-basis: 22%"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            tag="div"
                            class="d-flex flex-column flex-grow-1"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-autocomplete
                              dense
                              outlined
                              :items="products"
                              item-value="id"
                              item-text="name"
                              :error-messages="errors[0]"
                              readonly
                              label="Product"
                              v-model="subLineItem.productId"
                              class=" ft font-weight-medium font-size-sm ma-1"
                            />
                          </ValidationProvider>

                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|numeric"
                            tag="div"
                            class="d-flex flex-column flex-grow-1"
                          >
                            <v-text-field
                              dense
                              outlined
                              :error-messages="errors[0]"
                              v-model="subLineItem.quantity"
                              class=" ft font-weight-medium font-size-sm ma-1"
                              label="Quantity (Litres)"
                              :readonly="!subLineItem.isNewLineItem"
                            />
                          </ValidationProvider>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-if="sharedProductsAndQuantity.length > 0"
                                v-on="on"
                                fab
                                small
                                @click="removeLineItem(index)"
                                class="mt-1"
                                color="red"
                                icon
                                ><i class="material-icons font-size-md"
                                  >close</i
                                ></v-btn
                              >
                            </template>
                            <span class="ft font-weight-medium"
                              >Remove Item</span
                            >
                          </v-tooltip>
                        </div>
                      </div>

                      <div class="d-flex flex-column flex-grow-1">
                        <div class="d-flex flex-column flex-grow-1">
                          <div
                            class="d-flex flex-row pb-2"
                            style="justify-content: flex-end;"
                          >
                            <v-btn
                              small
                              color="primary"
                              text
                              :disabled="
                                parseInt(trackLineItemQuantity) -
                                  sharedProductsAndQuantity.reduce(
                                    (acc, item) => {
                                      return (acc += isNaN(
                                        parseInt(item.quantity)
                                      )
                                        ? 0
                                        : parseInt(item.quantity));
                                    },
                                    0
                                  ) <
                                  0 || loading
                              "
                              @click="pushToLineItem(lineItem)"
                            >
                              <i class="material-icons font-size-md ml-n2"
                                >add</i
                              >
                              <span class="ft font-weight-medium font-size-sm"
                                >Share Quantity</span
                              >
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-row mr-2 mt-2"
                    style="justify-content: flex-end;"
                  >
                    <v-btn
                      color="primary"
                      type="submit"
                      :disabled="
                        loading ||
                          sharedProductsAndQuantity.length === 0 ||
                          parseInt(trackLineItemQuantity) -
                            sharedProductsAndQuantity.reduce((acc, item) => {
                              return (acc += isNaN(parseInt(item.quantity))
                                ? 0
                                : parseInt(item.quantity));
                            }, 0) <
                            0
                      "
                      :loading="loading"
                    >
                      <span class="ft">Generate Delivery</span>
                    </v-btn>
                  </div>
                  <div class="d-flex flex-row flex-grow-1 mx-2 mt-2">
                    <v-expansion-panels
                      flat
                      focusable
                      class=" box-shadow-light"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <div
                            class="d-flex flex-row flex-grow-1"
                            style="justify-content: space-between;align-items: center"
                          >
                            <span class=" ft font-weight-bold font-size-md "
                              >View already shared products and associated
                              branches</span
                            >
                            <i
                              class="material-icons-outlined mr-3 primary--text"
                              >touch_app</i
                            >
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table
                            v-if="details"
                            class=" ft"
                            flat
                            :headers="[
                              ...headers,
                              {
                                text: 'Delivery Status',
                                value: 'isDeliveryAccepted'
                              }
                            ]"
                            :items="details.orderLineItemId.productDeliveries"
                            fixed-header
                          >
                            <template #item.branchId="{item}">
                              <v-chip label small color="primary">
                                <span class=" ft font-weight-bold">{{
                                  item.branchId.name
                                }}</span>
                              </v-chip>
                            </template>
                            <template #item.productId>
                              <v-chip label small color="primary">
                                <span class=" ft font-weight-bold">{{
                                  details.productId.name
                                }}</span>
                              </v-chip>
                            </template>
                            <template #item.isDeliveryAccepted="{item}">
                              <v-chip
                                label
                                :color="
                                  item.isDeliveryAccepted
                                    ? '#9efb9e66'
                                    : '#f98c6b'
                                "
                                class="ft font-weight-medium mr-2 text-uppercase"
                                small
                              >
                                <span
                                  :style="{
                                    color: item.isDeliveryAccepted
                                      ? 'rgba(3, 91, 3, 0.83)'
                                      : '#862c00'
                                  }"
                                  class="font-weight-bold"
                                  >{{
                                    item.isDeliveryAccepted
                                      ? "Confirmed"
                                      : "Pending"
                                  }}</span
                                >
                              </v-chip>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
/* eslint-disable no-unused-vars */

import { timestamp } from "../../../../../filters/timestamp";
import { currencyFormat } from "../../../../../filters/currencyFormat";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field must be numeric"
});
export default {
  name: "OrderDetailsBottomSheet",
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: () =>
      import(`../../../../../components/SkeletonPreloader`)
  },
  props: {
    details: {
      type: Object,
      default: null
    },
    state: Boolean,
    loading: Boolean,
    pageLoading: Boolean
  },
  filters: {
    timestamp,
    currencyFormat
  },
  computed: {
    ...mapGetters({
      branches: "orders/getAllBranches",
      products: "orders/getAllProducts",
      resetFormState: "getResetFormState",
      resetFormValues: "orders/getResetFormValues"
    })
  },
  data: () => ({
    orderLineItems: [],
    lineItem: null,
    sharedProductsAndQuantity: [],
    trackLineItemQuantity: null,
    originalLineItemQuantity: null,
    filteredBranches: [],
    headers: [
      { text: "Branch", value: "branchId" },
      { text: "Product", value: "productId" },
      { text: "Quantity (Ltr)", value: "quantity" }
    ]
  }),
  watch: {
    resetFormState(state) {
      if (state) {
        this.sharedProductsAndQuantity = [...this.resetFormValues];
      }
      this.$store.dispatch("resetFormState", false, { root: true });
    },
    details(payload) {
      this.lineItem = { ...payload, isNewLineItem: false };
      this.trackLineItemQuantity = this.originalLineItemQuantity = parseInt(
        this.lineItem.quantity
      );
      const sharedBranches = payload.orderLineItemId.productDeliveries.map(
        delivery => delivery?.branchId?.id
      );
      this.filteredBranches = this.branches?.filter(
        branch => !sharedBranches.includes(branch.id)
      );
      this.sharedProductsAndQuantity = [];
    },
    sharedProductsAndQuantity: {
      handler(payload) {
        // quantity calculator
        if (payload.length > 0) {
          payload.forEach(() => {
            this.lineItem.quantity =
              parseInt(this.trackLineItemQuantity) -
              payload.reduce((acc, item) => {
                return (acc += isNaN(parseInt(item.quantity))
                  ? 0
                  : parseInt(item.quantity));
              }, 0);
          });
        } else {
          this.lineItem.quantity = parseInt(this.originalLineItemQuantity);
        }
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.$emit("actions", "details");
    },
    pushToLineItem({ productId }) {
      this.sharedProductsAndQuantity.push({
        productId: productId.id,
        branchList: this.branches.filter(
          branch =>
            !this.sharedProductsAndQuantity
              .map(sharedItem => sharedItem.branchId)
              .includes(branch.id)
        ),
        quantity: "",
        branchId: "",
        isNewLineItem: true
      });
    },
    removeLineItem(index) {
      this.sharedProductsAndQuantity.splice(index, 1);
    },
    createDeliveries() {
      let {
        quantity,
        branchId,
        productId,
        orderId,
        id,
        orderLineItemId
      } = this.lineItem;
      let payload = {
        date: orderLineItemId.date,
        orderId: orderId.id,
        remainingQuantity: {
          quantity: parseInt(quantity),
          branchId: branchId?.id,
          productId: productId?.id,
          orderLineItemId: orderLineItemId.id
        },
        deliveriesLineItems: this.sharedProductsAndQuantity.map(
          ({ branchList, isNewLineItem, ...rest }) => {
            return {
              ...rest,
              quantity: parseInt(rest.quantity),
              orderLineItemId: orderLineItemId.id
            };
          }
        )
      };
      this.$emit("deliveries", {
        body: payload,
        dialog: { idx: "details", state: false }
      });
    }
  }
};
</script>
<style scoped>
.v-expansion-panel-content /deep/ .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
